/* Montserrat */

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('./fonts/Montserrat-Italic.woff2') format('woff2'),
        url('./fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url('./fonts/Montserrat-ThinItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url('./fonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('./fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('./fonts/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url('./fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('./fonts/Montserrat-Black.woff2') format('woff2'),
        url('./fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('./fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('./fonts/Montserrat-Medium.woff2') format('woff2'),
        url('./fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url('./fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('./fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('./fonts/Montserrat-Thin.woff2') format('woff2'),
        url('./fonts/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('./fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url('./fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('./fonts/Montserrat-Light.woff2') format('woff2'),
        url('./fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('./fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('./fonts/Montserrat-Regular.woff2') format('woff2'),
        url('./fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('./fonts/Montserrat-Bold.woff2') format('woff2'),
        url('./fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('./fonts/Montserrat-LightItalic.woff2') format('woff2'),
        url('./fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('./fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('./fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* IBM Plex Mono */


@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Medium'), local('IBMPlexMono-Medium'),
        url('./fonts/IBMPlexMono-Medium.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Bold Italic'), local('IBMPlexMono-BoldItalic'),
        url('./fonts/IBMPlexMono-BoldItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Light Italic'), local('IBMPlexMono-LightItalic'),
        url('./fonts/IBMPlexMono-LightItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono ExtraLight'), local('IBMPlexMono-ExtraLight'),
        url('./fonts/IBMPlexMono-ExtraLight.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Thin'), local('IBMPlexMono-Thin'),
        url('./fonts/IBMPlexMono-Thin.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono ExtraLight Italic'), local('IBMPlexMono-ExtraLightItalic'),
        url('./fonts/IBMPlexMono-ExtraLightItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Regular'), local('IBMPlexMono-Regular'),
        url('./fonts/IBMPlexMono-Regular.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Thin Italic'), local('IBMPlexMono-ThinItalic'),
        url('./fonts/IBMPlexMono-ThinItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Light'), local('IBMPlexMono-Light'),
        url('./fonts/IBMPlexMono-Light.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono SemiBold Italic'), local('IBMPlexMono-SemiBoldItalic'),
        url('./fonts/IBMPlexMono-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Bold'), local('IBMPlexMono-Bold'),
        url('./fonts/IBMPlexMono-Bold.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono SemiBold'), local('IBMPlexMono-SemiBold'),
        url('./fonts/IBMPlexMono-SemiBold.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Medium Italic'), local('IBMPlexMono-MediumItalic'),
        url('./fonts/IBMPlexMono-MediumItalic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
        url('./fonts/IBMPlexMono-Italic.woff2') format('woff2'),
        url('./fonts/IBMPlexMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}





